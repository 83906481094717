@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'DM Serif';
  src: url("assets/font/DMSerifDisplay-Regular.ttf") format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'DM Serif';
  src: url("assets/font/DMSerifDisplay-Italic.ttf") format('truetype');
  font-style: italic;
}

@font-face {
  font-family: 'DM Sans';
  src: url("assets/font/DMSans-VariableFont_opsz,wght.ttf") format('truetype');
  font-style: normal;
}

@font-face {
  font-family: 'DM Sans';
  src: url("assets/font/DMSans-Italic-VariableFont_opsz,wght.ttf") format('truetype');
  font-style: italic;
}

.navbar-custom {
  @apply flex justify-between items-center w-full top-0 z-50 transition duration-300 ease-in-out;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
}

.navbar-sticky {
  position: fixed;
  top: 0;
  width: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
